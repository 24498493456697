
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "aabf94f8-2ae8-4ef8-bcb8-402814f22c46"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
