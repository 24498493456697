import { default as aboutC5WryykVp8Meta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/about.vue?macro=true";
import { default as awardseGg4uZSYVLMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/awards.vue?macro=true";
import { default as backBiIs6VDN6FMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/back.vue?macro=true";
import { default as banksiBLyP89lLcMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/banks.vue?macro=true";
import { default as cinemabaRtpT2s22Meta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/cinema.vue?macro=true";
import { default as events9sZHu7P4wlMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/events.vue?macro=true";
import { default as indexwDnaEtuClEMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/index.vue?macro=true";
import { default as login14AXUHCTltMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/login.vue?macro=true";
import { default as parkingAPU0fw5ZDHMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/parking.vue?macro=true";
import { default as parkingGSsNVTVnrwcmMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/parkingGS.vue?macro=true";
import { default as privacyPk2rrQntUiMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/privacy.vue?macro=true";
import { default as profilerXZoFkgvS0Meta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/profile.vue?macro=true";
import { default as cataloguep4qG1ZXJouMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/catalogue.vue?macro=true";
import { default as contestsC3tP5vgI8Meta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/contest.vue?macro=true";
import { default as discounts3ICFtzrP4EMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/discounts.vue?macro=true";
import { default as gamefkMHY4F6SWMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/game.vue?macro=true";
import { default as indexNZnnHyUHe9Meta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/index.vue?macro=true";
import { default as quizjK1f3W2D2OMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/quiz.vue?macro=true";
import { default as rulesVPNDNXZddWMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/rules.vue?macro=true";
import { default as survey0z1UbGn5WWMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/survey.vue?macro=true";
import { default as transactionsUXrINMQ6OyMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/transactions.vue?macro=true";
import { default as _91code_93zsrf6cIP8qMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/qrcode/[code].vue?macro=true";
import { default as receiptsJZoxnSANhyMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/receipts.vue?macro=true";
import { default as _91shop_93FerfNOK2bYMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/shops/[shop].vue?macro=true";
import { default as indexSBMLoSpfnnMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/shops/index.vue?macro=true";
import { default as mapcpfhSd13NvMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/shops/map.vue?macro=true";
import { default as storez7JgSOJbnrMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/store.vue?macro=true";
import { default as testsfxOv9CrgpQMeta } from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/tests.vue?macro=true";
export default [
  {
    name: aboutC5WryykVp8Meta?.name ?? "about",
    path: aboutC5WryykVp8Meta?.path ?? "/about",
    meta: aboutC5WryykVp8Meta || {},
    alias: aboutC5WryykVp8Meta?.alias || [],
    redirect: aboutC5WryykVp8Meta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: awardseGg4uZSYVLMeta?.name ?? "awards",
    path: awardseGg4uZSYVLMeta?.path ?? "/awards",
    meta: awardseGg4uZSYVLMeta || {},
    alias: awardseGg4uZSYVLMeta?.alias || [],
    redirect: awardseGg4uZSYVLMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: backBiIs6VDN6FMeta?.name ?? "back",
    path: backBiIs6VDN6FMeta?.path ?? "/back",
    meta: backBiIs6VDN6FMeta || {},
    alias: backBiIs6VDN6FMeta?.alias || [],
    redirect: backBiIs6VDN6FMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/back.vue").then(m => m.default || m)
  },
  {
    name: banksiBLyP89lLcMeta?.name ?? "banks",
    path: banksiBLyP89lLcMeta?.path ?? "/banks",
    meta: banksiBLyP89lLcMeta || {},
    alias: banksiBLyP89lLcMeta?.alias || [],
    redirect: banksiBLyP89lLcMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/banks.vue").then(m => m.default || m)
  },
  {
    name: cinemabaRtpT2s22Meta?.name ?? "cinema",
    path: cinemabaRtpT2s22Meta?.path ?? "/cinema",
    meta: cinemabaRtpT2s22Meta || {},
    alias: cinemabaRtpT2s22Meta?.alias || [],
    redirect: cinemabaRtpT2s22Meta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/cinema.vue").then(m => m.default || m)
  },
  {
    name: events9sZHu7P4wlMeta?.name ?? "events",
    path: events9sZHu7P4wlMeta?.path ?? "/events",
    meta: events9sZHu7P4wlMeta || {},
    alias: events9sZHu7P4wlMeta?.alias || [],
    redirect: events9sZHu7P4wlMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/events.vue").then(m => m.default || m)
  },
  {
    name: indexwDnaEtuClEMeta?.name ?? "index",
    path: indexwDnaEtuClEMeta?.path ?? "/",
    meta: indexwDnaEtuClEMeta || {},
    alias: indexwDnaEtuClEMeta?.alias || [],
    redirect: indexwDnaEtuClEMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login14AXUHCTltMeta?.name ?? "login",
    path: login14AXUHCTltMeta?.path ?? "/login",
    meta: login14AXUHCTltMeta || {},
    alias: login14AXUHCTltMeta?.alias || [],
    redirect: login14AXUHCTltMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: parkingAPU0fw5ZDHMeta?.name ?? "parking",
    path: parkingAPU0fw5ZDHMeta?.path ?? "/parking",
    meta: parkingAPU0fw5ZDHMeta || {},
    alias: parkingAPU0fw5ZDHMeta?.alias || [],
    redirect: parkingAPU0fw5ZDHMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/parking.vue").then(m => m.default || m)
  },
  {
    name: parkingGSsNVTVnrwcmMeta?.name ?? "parkingGS",
    path: parkingGSsNVTVnrwcmMeta?.path ?? "/parkingGS",
    meta: parkingGSsNVTVnrwcmMeta || {},
    alias: parkingGSsNVTVnrwcmMeta?.alias || [],
    redirect: parkingGSsNVTVnrwcmMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/parkingGS.vue").then(m => m.default || m)
  },
  {
    name: privacyPk2rrQntUiMeta?.name ?? "privacy",
    path: privacyPk2rrQntUiMeta?.path ?? "/privacy",
    meta: privacyPk2rrQntUiMeta || {},
    alias: privacyPk2rrQntUiMeta?.alias || [],
    redirect: privacyPk2rrQntUiMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: profilerXZoFkgvS0Meta?.name ?? "profile",
    path: profilerXZoFkgvS0Meta?.path ?? "/profile",
    meta: profilerXZoFkgvS0Meta || {},
    alias: profilerXZoFkgvS0Meta?.alias || [],
    redirect: profilerXZoFkgvS0Meta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: cataloguep4qG1ZXJouMeta?.name ?? "promo-promo-catalogue",
    path: cataloguep4qG1ZXJouMeta?.path ?? "/promo/:promo()/catalogue",
    meta: cataloguep4qG1ZXJouMeta || {},
    alias: cataloguep4qG1ZXJouMeta?.alias || [],
    redirect: cataloguep4qG1ZXJouMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/catalogue.vue").then(m => m.default || m)
  },
  {
    name: contestsC3tP5vgI8Meta?.name ?? "promo-promo-contest",
    path: contestsC3tP5vgI8Meta?.path ?? "/promo/:promo()/contest",
    meta: contestsC3tP5vgI8Meta || {},
    alias: contestsC3tP5vgI8Meta?.alias || [],
    redirect: contestsC3tP5vgI8Meta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/contest.vue").then(m => m.default || m)
  },
  {
    name: discounts3ICFtzrP4EMeta?.name ?? "promo-promo-discounts",
    path: discounts3ICFtzrP4EMeta?.path ?? "/promo/:promo()/discounts",
    meta: discounts3ICFtzrP4EMeta || {},
    alias: discounts3ICFtzrP4EMeta?.alias || [],
    redirect: discounts3ICFtzrP4EMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/discounts.vue").then(m => m.default || m)
  },
  {
    name: gamefkMHY4F6SWMeta?.name ?? "promo-promo-game",
    path: gamefkMHY4F6SWMeta?.path ?? "/promo/:promo()/game",
    meta: gamefkMHY4F6SWMeta || {},
    alias: gamefkMHY4F6SWMeta?.alias || [],
    redirect: gamefkMHY4F6SWMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/game.vue").then(m => m.default || m)
  },
  {
    name: indexNZnnHyUHe9Meta?.name ?? "promo-promo",
    path: indexNZnnHyUHe9Meta?.path ?? "/promo/:promo()",
    meta: indexNZnnHyUHe9Meta || {},
    alias: indexNZnnHyUHe9Meta?.alias || [],
    redirect: indexNZnnHyUHe9Meta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/index.vue").then(m => m.default || m)
  },
  {
    name: quizjK1f3W2D2OMeta?.name ?? "promo-promo-quiz",
    path: quizjK1f3W2D2OMeta?.path ?? "/promo/:promo()/quiz",
    meta: quizjK1f3W2D2OMeta || {},
    alias: quizjK1f3W2D2OMeta?.alias || [],
    redirect: quizjK1f3W2D2OMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/quiz.vue").then(m => m.default || m)
  },
  {
    name: rulesVPNDNXZddWMeta?.name ?? "promo-promo-rules",
    path: rulesVPNDNXZddWMeta?.path ?? "/promo/:promo()/rules",
    meta: rulesVPNDNXZddWMeta || {},
    alias: rulesVPNDNXZddWMeta?.alias || [],
    redirect: rulesVPNDNXZddWMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/rules.vue").then(m => m.default || m)
  },
  {
    name: survey0z1UbGn5WWMeta?.name ?? "promo-promo-survey",
    path: survey0z1UbGn5WWMeta?.path ?? "/promo/:promo()/survey",
    meta: survey0z1UbGn5WWMeta || {},
    alias: survey0z1UbGn5WWMeta?.alias || [],
    redirect: survey0z1UbGn5WWMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/survey.vue").then(m => m.default || m)
  },
  {
    name: transactionsUXrINMQ6OyMeta?.name ?? "promo-promo-transactions",
    path: transactionsUXrINMQ6OyMeta?.path ?? "/promo/:promo()/transactions",
    meta: transactionsUXrINMQ6OyMeta || {},
    alias: transactionsUXrINMQ6OyMeta?.alias || [],
    redirect: transactionsUXrINMQ6OyMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/promo/[promo]/transactions.vue").then(m => m.default || m)
  },
  {
    name: _91code_93zsrf6cIP8qMeta?.name ?? "qrcode-code",
    path: _91code_93zsrf6cIP8qMeta?.path ?? "/qrcode/:code()",
    meta: _91code_93zsrf6cIP8qMeta || {},
    alias: _91code_93zsrf6cIP8qMeta?.alias || [],
    redirect: _91code_93zsrf6cIP8qMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/qrcode/[code].vue").then(m => m.default || m)
  },
  {
    name: receiptsJZoxnSANhyMeta?.name ?? "receipts",
    path: receiptsJZoxnSANhyMeta?.path ?? "/receipts",
    meta: receiptsJZoxnSANhyMeta || {},
    alias: receiptsJZoxnSANhyMeta?.alias || [],
    redirect: receiptsJZoxnSANhyMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/receipts.vue").then(m => m.default || m)
  },
  {
    name: _91shop_93FerfNOK2bYMeta?.name ?? "shops-shop",
    path: _91shop_93FerfNOK2bYMeta?.path ?? "/shops/:shop()",
    meta: _91shop_93FerfNOK2bYMeta || {},
    alias: _91shop_93FerfNOK2bYMeta?.alias || [],
    redirect: _91shop_93FerfNOK2bYMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/shops/[shop].vue").then(m => m.default || m)
  },
  {
    name: indexSBMLoSpfnnMeta?.name ?? "shops",
    path: indexSBMLoSpfnnMeta?.path ?? "/shops",
    meta: indexSBMLoSpfnnMeta || {},
    alias: indexSBMLoSpfnnMeta?.alias || [],
    redirect: indexSBMLoSpfnnMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/shops/index.vue").then(m => m.default || m)
  },
  {
    name: mapcpfhSd13NvMeta?.name ?? "shops-map",
    path: mapcpfhSd13NvMeta?.path ?? "/shops/map",
    meta: mapcpfhSd13NvMeta || {},
    alias: mapcpfhSd13NvMeta?.alias || [],
    redirect: mapcpfhSd13NvMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/shops/map.vue").then(m => m.default || m)
  },
  {
    name: storez7JgSOJbnrMeta?.name ?? "store",
    path: storez7JgSOJbnrMeta?.path ?? "/store",
    meta: storez7JgSOJbnrMeta || {},
    alias: storez7JgSOJbnrMeta?.alias || [],
    redirect: storez7JgSOJbnrMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/store.vue").then(m => m.default || m)
  },
  {
    name: testsfxOv9CrgpQMeta?.name ?? "tests",
    path: testsfxOv9CrgpQMeta?.path ?? "/tests",
    meta: testsfxOv9CrgpQMeta || {},
    alias: testsfxOv9CrgpQMeta?.alias || [],
    redirect: testsfxOv9CrgpQMeta?.redirect,
    component: () => import("/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/pages/tests.vue").then(m => m.default || m)
  }
]